<template>
    <v-app>
        <v-app-bar app style="background:white;"> 
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <a :href="appjson.settings.url"><v-img :src="appjson.settings.logo" height="38px" position="left" class="ml-2" contain/></a>
            <v-toolbar-title class="d-none d-sm-block ml-n8">Container Anfrage</v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        class="px-2 py-6"
        >
            <v-container cols="12" class="font-weight-bold">
                <v-row><v-col class="mt-n3">
                    <v-app-bar-nav-icon :color="appjson.settings.design.color" @click="drawer = !drawer" class="ml-n1 mr-3 mt-n1"></v-app-bar-nav-icon>
                    <a :href="appjson.settings.url"><span class="md title">{{ appjson.settings.name }}</span></a>    
                    <v-divider></v-divider>
                </v-col></v-row>
                <v-row v-for="(link,linkIndex) in appjson.settings.links" :key="linkIndex">
                    <v-col>
                        <a :href="link.href" style="text-decoration:none;">
                            <v-icon class="-mt-1 mr-5" :color="appjson.settings.design.color">{{link.icon}}</v-icon>
                            <span>{{ link.text }}</span>
                        </a>
                    </v-col>
                </v-row>
            </v-container>
        </v-navigation-drawer>

        <v-main >
            <v-container>
                <v-row>
                    <v-col class="pa-0 pa-sm-4">
                        <v-stepper v-model="step">
                            <v-stepper-header>
                                <v-stepper-step :color="appjson.settings.design.color" step="1" :complete="step >= 1 || (typeof step =='string')" @click="stepallowed >= 1 ? step = 1 : {}" class="cursor-pointer">Lieferaddresse</v-stepper-step>

                                <v-divider></v-divider>
                                <v-stepper-step :color="appjson.settings.design.color" step="2" :complete="step >= 2 || (typeof step =='string')" @click="stepallowed >= 2 ? step = 2 : {}" class="cursor-pointer">Abfallart wählen</v-stepper-step>
                                
                                <v-divider></v-divider>
                                <v-stepper-step :color="appjson.settings.design.color" step="3" :complete="step >= 3 || (typeof step =='string')" @click="stepallowed >= 3 ? step = 3 : {}" class="cursor-pointer">Container wählen</v-stepper-step>

                                <v-divider></v-divider>
                                <v-stepper-step :color="appjson.settings.design.color" step="4" :complete="step >= 4 || (typeof step =='string')" @click="stepallowed >= 4 ? step = 4 : {}" class="cursor-pointer">Lieferdaten</v-stepper-step>

                                <v-divider></v-divider>
                                <v-stepper-step :color="appjson.settings.design.color" step="5" :complete="step >= 5 || (typeof step =='string')" @click="stepallowed >= 5 ? step = 5 : {}" class="cursor-pointer">Zusammenfassung</v-stepper-step>
                            </v-stepper-header>

                            
                            <v-stepper-content step="1" class="pa-6">    
                                <br>
                                <v-text-field :color="appjson.settings.design.color" prepend-inner-icon="mdi-map-marker" outlined v-model.number="creds.zip" label="Postleitzahl des Abstellortes wählen" type="number" min="1000" max="10000" required />
                                <div class="red--text font-semibold" v-show="rule_zip != null">
                                    {{rule_zip}}
                                </div>
                                
                                <v-btn :color="appjson.settings.design.color" @click="next()" :disabled="continueallowed == false" :class="appjson.settings.design.text">Weiter</v-btn>
                            </v-stepper-content>

                            <v-stepper-content step="2" class="pa-2 pa-md-6">
                                <h3>Abfallarten wählen</h3> 
                                <br>
                                <v-container>
                                    <v-row>
                                        <v-col v-for="(localwaste,key) in appjson.abfall" :key="key+'2'" cols="6" sm="4" md="3" xl="2" class="ma-0 pa-1">
                                            <v-card class="ma-0 pa-0" style="height:100%;overflow:hidden;" @click="toggleWaste(key)">
                                                <v-img :src="localwaste.img" height="110px"></v-img>
                                                
                                                <v-checkbox readonly :color="appjson.settings.design.color" v-model="appjson.abfall[key].selected" :label="localwaste.name" class="mx-5 d-none d-lg-block" style="vertical-align:top;"></v-checkbox>
                                                
                                                <v-simple-checkbox dense readonly :color="appjson.settings.design.color" v-model="appjson.abfall[key].selected" :label="localwaste.name" class="text-center mt-4 d-block d-lg-none"></v-simple-checkbox>
                                                <v-card-text dense class="text-center d-block d-lg-none">{{localwaste.name}}</v-card-text>
                    
                                                

                                                <v-dialog v-model="localwaste.dialog" width="500">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn text dense  v-bind="attrs" v-on="on" elevation="0" style="opacity:0.5;padding:3px;margin-left:1.4em;margin-bottom:10px;margin-top:-10px;width:80%;" class="d-block d-lg-none"  :class="appjson.settings.design.text">
                                                            <v-icon :color="appjson.settings.design.color" small >mdi-information</v-icon>
                                                            <small style="word-break: break-word;" :style="'color:'+appjson.settings.design.color">
                                                                Container Infos
                                                            </small>
                                                        </v-btn>
                                                        <v-btn text dense  v-bind="attrs" v-on="on" elevation="0" style="opacity:0.5;padding:3px;margin-left:1.4em;margin-bottom:10px;margin-top:-10px;" class="d-none d-lg-block"  :class="appjson.settings.design.text">
                                                            <v-icon :color="appjson.settings.design.color" small >mdi-information</v-icon>
                                                            <small style="word-break: break-word;" :style="'color:'+appjson.settings.design.color">
                                                                Container Infos
                                                            </small>
                                                        </v-btn>
                                                    </template>

                                                    <v-card>
                                                        <v-card-title class="headline lighten-2" :style="'background:'+appjson.settings.design.color" :class="appjson.settings.design.text">{{ localwaste.name}}</v-card-title>

                                                        <div class="pa-6">
                                                            {{ localwaste.description }}
                                                        </div>

                                                        <v-expansion-panels class="pa-6 pt-0">
                                                            <v-expansion-panel v-for="(value,key) in localwaste.info" :key="key">
                                                                <v-expansion-panel-header :style="'color:'+appjson.settings.design.color">{{ value.title }}</v-expansion-panel-header>
                                                                <v-expansion-panel-content class="text--gray">
                                                                    {{ value.text }}
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>

                                                        <v-divider></v-divider>

                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                color="primary"
                                                                text
                                                                @click="localwaste.dialog = false"
                                                            >
                                                                Schließen
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <br>
                                
                                <v-btn text @click="prev()" class="mr-2"><span :style="'color:'+ appjson.settings.design.color">Zurück</span></v-btn>
                                <v-btn :color="appjson.settings.design.color" @click="next()" :disabled="continueallowed == false" :class="appjson.settings.design.text">Weiter</v-btn>
                            </v-stepper-content>

                            <v-stepper-content step="3" class="pa-3">
                                <v-container>
                                    <v-row v-for="(localwaste,key) in selectedWaste" :key="key+'3'">
                                        <v-container>
                                            <v-row>
                                                <v-img :src="appjson.abfall[localwaste].img" height="110" class="mt-4"></v-img>
                                            </v-row>
                                            <br>
                                            <v-row class="my-1 title" style="padding-left:2px;">
                                                <h3>Container wählen für {{ appjson.abfall[localwaste].name }} </h3>
                                            </v-row>
                                            <br>
                                            <v-row dense class="px-0 mx-n3"> 
                                                <v-col v-for="(localsc,scKey) in appjson.abfall[localwaste].suitablecontainers" :key="scKey+'sc'" cols="6" sm="4" md="3" xl="2"  class="ma-0 pa-1">
                                                    <v-card @click="toggleContainer(localwaste,localsc)" :style="shadowContainer(localwaste,localsc)" :elevation="elevateContainer(localwaste,localsc)">
                                                        <v-img :src="localsc.img" height="130px" contain></v-img>
                                                        <v-card-title> {{ localsc.name }} </v-card-title>
                                                        <v-card-text>
                                                            {{ localsc.description }}
                                                            <br>
                                                            Volumen: {{localsc.volume}} m³
                                                            <br>
                                                            Kranverladung möglich
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-btn text :color="appjson.settings.design.color" >Auswählen</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <br>
                                        </v-container>
                                    </v-row>
                                </v-container>

                                <v-btn text @click="prev()" class="mr-2"><span :style="'color:'+ appjson.settings.design.color">Zurück</span></v-btn>
                                <v-btn :color="appjson.settings.design.color" @click="next()" :disabled="continueallowed == false" :class="appjson.settings.design.text">Weiter</v-btn>
                            </v-stepper-content>


                            <v-stepper-content step="4" class="px-0 px-sm-4 py-4">
                                <v-container>
                                    <v-row >
                                        <v-col cols="12" sm="6" lg="4" xl="3">
                                            <h3>Lieferdatum und Kontaktdaten</h3>
                                            <br>
                                            <v-date-picker v-model="date" elevation="2" ref="picker" :color="appjson.settings.design.color" :min="deliverday" :show-current="false" full-width first-day-of-week="1" locale="de" scrollable></v-date-picker>
                                        </v-col>

                                        <v-col>
                                            <table>
                                                <tr>
                                                    <td colspan="2">
                                                        <v-text-field v-model.number="creds.name" label="Name" type="text" class="-mb-4" required/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <v-text-field v-model.number="creds.city" label="Ortschaft" type="text" class="-mb-4" required/>
                                                        <div class="red--text" v-show="rule_city != null">
                                                            {{ rule_city }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <v-text-field v-model.number="creds.address" label="Straße & Hausnummer" type="text" class="-mb-4" required/>
                                                        <div class="red--text" v-show="rule_address != null">
                                                            {{ rule_address }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <v-text-field v-model.number="creds.email" label="Email" type="email" class="-mb-4" required/>
                                                        <div class="red--text" v-show="rule_email != null">
                                                            {{rule_email}}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <v-text-field v-model="creds.number" label="Telefonnummer" type="text" class="mt-2 -mb-4" required/>
                                                        <div class="red--text" v-show="rule_tel != null">
                                                            {{rule_tel}}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </v-col>
                                    </v-row>
                                </v-container>

                                <v-btn text @click="prev()" class="mr-2"><span :style="'color:'+ appjson.settings.design.color">Zurück</span></v-btn>
                                <v-btn :color="appjson.settings.design.color" @click="next()" :disabled="continueallowed == false" :class="appjson.settings.design.text">Weiter</v-btn>
                            </v-stepper-content>
                            
                            <v-stepper-content step="5" class="px-0 px-sm-4 py-4">
                                <v-alert type="success" v-show="popup == true">
                                    Vielen Dank, dass Sie mit uns in Verbindung getreten sind. Wir werden uns in kürze bei Ihnen melden!
                                </v-alert>

                                <v-container>
                                    <v-row>
                                        <v-col cols="12" lg="6">
                                            <v-card elevation="3" style="height:100%">
                                                <v-card-title><v-icon class="mr-2"  :color="appjson.settings.design.color">mdi-human</v-icon>Kontakt</v-card-title>
                                                <v-card-text>
                                                    <table>
                                                        <tr>
                                                            <td class="w-1/2">Name</td>
                                                            <td class="w-1/2">{{creds.name}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-1/2">Addresse</td>
                                                            <td class="w-1/2">{{creds.address}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-1/2">Ortschaft</td>
                                                            <td class="w-1/2">{{creds.zip+' '+creds.city}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-1/2">Telefon</td>
                                                            <td class="w-1/2">{{creds.number}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-1/2">Email</td>
                                                            <td class="w-1/2"><div style="word-break: break-word;">{{creds.email}}</div></td>
                                                        </tr>
                                                    </table>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col>
                                            <v-card elevation="3">
                                                <v-card-title><v-icon class="mr-2"  :color="appjson.settings.design.color">mdi-package</v-icon>Angebot</v-card-title>
                                                <v-card-text>
                                                    <table>
                                                        <tr>
                                                            <td class="w-1/2">Grundstück</td>
                                                            <td class="w-full block">
                                                                <v-radio-group v-model="property" row style="margin-bottom: -22px;margin-top: -10px;">
                                                                    <v-radio label="Privat" value="privat"></v-radio>
                                                                    <v-radio label="Öffentlich" value="öffentlich"></v-radio>
                                                                </v-radio-group>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-1/2">Liefertermin</td>
                                                            <td class="w-1/2"> {{ new Date(date).toLocaleDateString('de-DE') }} </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-1/2">Abfall</td>
                                                            <td class="w-1/2"> {{ listAbfall() }} </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan=2 class="w-1/2">
                                                                <br>
                                                                <v-textarea rows="3" v-model.number="creds.note" label="Bemerkung anfügen ..." type="text" class="mb-n8" outlined/>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>

                                <br>
                                <v-container v-if="stepallowed == 5">
                                    <h3>Container</h3>
                                    <br>
                                    <v-row v-for="(localsumwaste, index) in selectedWaste" :key="index+'4'">
                                        <v-col v-if="localsumwaste != null">
                                            <v-card elevation="3">
                                                <v-container>
                                                    <v-row class="text-center">
                                                        <v-col cols="12" sm="12" md="3" class="pa-4 pb-0">
                                                            <v-img max-height="200px" contain :src="appjson.abfall[localsumwaste].selectedcontainer.img"></v-img>
                                                        </v-col>
                                                        <v-col cols="12" sm="4" md="3" class="pa-0 pa-sm-auto">
                                                            <v-card-title style="display:inline-block !important;" class="pa-1 pa-sm-4">{{ appjson.abfall[localsumwaste].selectedcontainer.name}}</v-card-title>
                                                            <v-card-text >
                                                                {{ appjson.abfall[localsumwaste].selectedcontainer.description}}<br>
                                                                {{ appjson.abfall[localsumwaste].selectedcontainer.volume}}m³
                                                            </v-card-text>
                                                        </v-col>
                                                        <v-col cols="12" sm="4" md="3" class="pa-0 pa-sm-auto">
                                                            <v-card-title style="display:inline-block !important;"  class="pa-1 pa-sm-4">Geeignet für</v-card-title>
                                                            <v-card-text>
                                                                {{ listAbfall(appjson.abfall[localsumwaste].selectedcontainer.materials) }}
                                                            </v-card-text>
                                                        </v-col>
                                                        <v-col cols="12" sm="4" md="3" class="pa-0 pa-sm-auto">
                                                            <v-card-title style="display:inline-block !important;" class="pa-1 pa-sm-4">Verwendung</v-card-title>
                                                            <v-card-text>{{ appjson.abfall[localsumwaste].name }}</v-card-text>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>

                                <div class="text-center">
                                    <v-btn :color="appjson.settings.design.color" @click="send('anfrage');popup = true;scrollToTop()" class="mb-4 sm:w-auto" large :disabled="popup == true"><span :class="appjson.settings.design.text">Anfrage stellen*</span></v-btn>
                                </div>
                                <div class="px-4 text-justify"><small>*Mit dem drücken auf diesen Button akzeptieren Sie, dass wir Ihre eingegebenen Daten konform unserer Datenschutzerklärung speichern und im Sinne des Formulars verarbeiten dürfen.</small></div>
                            </v-stepper-content>
                        </v-stepper>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>

    //import importedjson from '../app.json'
    import importedjson from '../app.json'


    export default {
        name: "Container",
        metaInfo() {
            return {
                link: [
                    { rel: 'icon', href: this.appjson.settings.url+"/favicon.ico" },
                ],
                title: 'Container-Tool',
                titleTemplate: '%s | '+this.appjson.settings.name,
            }
        },
        data(){
            return this.initialdata()
            //return this.testdata()
        },
        computed:  {
            deliverday(){
                var dateObj = new Date(); 
                dateObj.setDate(dateObj.getDate() - 0);  
                return dateObj.toISOString().slice(0,10);
            },
            stepallowed(){
                var tmp = 1;
                var skip = false
                /* Step 1 */if(this.creds.zip >= 1000 && this.creds.zip <= 10000 && this.rule_zip == null){tmp++;}else skip = true
                /* Step 2*/if(!skip && this.selectedWaste[0] != null ){tmp = 3;}else skip = true
                /* Step 3*/if(!skip && String(this.appjson.containercount) == String(this.selectedWaste.length) ){tmp = 4;}else skip = true
                /* Step 4*/if(!skip && this.date != null &&
                            ((this.creds.address) != "" && (this.creds.address.length >= 5) && this.rule_address== null) && 
                            ((this.creds.name != ""))  && 
                            ((this.creds.number) != "" && this.rule_tel == null) && 
                            ((this.creds.city) != "" && this.rule_city == null) && 
                            ((this.creds.email != "") && (this.creds.email.includes('@')) && this.rule_email == null))
                {tmp = 5;}else skip = true

                return tmp
            },
            continueallowed(){
                return this.stepallowed > this.step
            },
            rule_zip(){
                var ruled = "Wir liefern leider nicht in diese Ortschaft"

                if(this.creds.zip == ""){return null}
                if(String(this.creds.zip).length < 4){return "Mindestens 4 Ziffern"}

                this.appjson.zips.forEach(element => {
                    //If null
                    if(element == null){return ruled}
                    //If string
                    if (typeof element == "string" && this.creds.zip == element){ruled = null}
                    //If range
                    if(this.creds.zip >= element[0]  && this.creds.zip <= element[1] )(ruled = null)
                });

                return ruled
            },
            rule_tel()
            {
                var ruled = "Bitte korrekte Telephonnummer angeben"
                if(this.creds.number.match && this.creds.number.match(/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm) || this.creds.number.length < 1){return null}
                return ruled
            },
            rule_email()
            {
                var ruled = "Bitte korrekte Email angeben"
                if(((this.creds.email.includes('@') && this.creds.email.includes('.') && !this.creds.email.includes(' ')) && this.creds.email.length >= 6) || this.creds.email.length < 1){return null}
                return ruled
            },
            rule_address(){
                var ruled = "Bitte vollständige Adresse angeben"
                if(this.creds.address == ""){return null}
                if(this.creds.address.match(/[\d]{1}/g) && this.creds.address.length >= 5){return null}
                return ruled
            },
            rule_city(){
                var ruled = "Bitte eine korrekte Ortschaft angeben"
                if(this.creds.city == ""){return null}
                if(this.creds.city.length >= 4){return null}
                return ruled
            },
            listofcontainers(){
                return JSON.stringify(this.containerselected)
            },
            selectedWaste(){
                var r = []
                
                for (const [key,value] of Object.entries(this.appjson.abfall)) {
                    if(value.selected == true)
                    {
                        r.push(key)
                    }
                }

                return r
            }
        },
        beforeCreate(){
            this.appjson = importedjson;

            this.$set(this.appjson,'containercount',0)

            // connect add suitable containers to waste
            for (const [key,value] of Object.entries(this.appjson.abfall)) {
                //value.suitablecontainers = {}
                this.$set(value,'suitablecontainers',{})
                //value.selected = false
                this.$set(value,'selected',false)
                //value.selectedcontainer = null
                this.$set(value,'selectedcontainer',null)

                var p = this.appjson.containers
                for (const [key1,value1] of Object.entries(p)) {
                    if(value1.materials.includes(key))
                    {
                        value.suitablecontainers[key1] = (value1);
                    }
                }
            }   

            this.axios.get(process.env.BASE_URL+"config.json").then((response) => {
                var c = response.data;c
                
                // escape varnish
                const rand=()=>Math.random(0).toString(36).substr(2);
                const token=(length)=>(rand()+rand()+rand()+rand()).substr(0,length);
                token

                this.axios.get(c.settings.url+c.settings.folder+"app.json").then((response) => {
                    this.appjson = Object.assign({},response.data,)

                    console.log('Appjson overwritten');
                }).catch(error => {
                    this.appjson = importedjson
                    console.log(error);
                    console.log('Axios error: keep local '+"config.json")
                }).finally(() =>{
                    this.$set(this.appjson,'containercount',0)
                    // connect add suitable containers to waste
                    for (const [key,value] of Object.entries(this.appjson.abfall)) {
                        this.$set(value,'suitablecontainers',{})
                        this.$set(value,'selected',false)
                        this.$set(value,'selectedcontainer',null)
                        this.$set(value,'dialog',false)

                        if(value.description == null || value.description == "")
                        {
                            this.$set(value,'description',"Für diese Abfallart scheint es keine Beschreibung zu geben")
                        }

                        if (value.info == null)
                        {
                            this.$set(value,'info',{})
                            this.$set(value.info,'hinein',{title:"WAS DARF HINEIN",text:"Für diesen Abfall fehlt Information"})
                            this.$set(value.info,'nichthinein',{title:"WAS DARF NICHT HINEIN",text:"Für diesen Abfall fehlt Information"})
                            this.$set(value.info,'tipp',{title:"UNSER TIPP",text:"Für diesen Abfall fehlt Information"})
                            this.$set(value.info,'hinweis',{title:"ALLGEMEINER HINWEIS",text:"Für diesen Abfall fehlt Information"})
                        }

                        var p = this.appjson.containers
                        for (const [key1,value1] of Object.entries(p)) {
                            if(value1.materials.includes(key))
                            {
                                value.suitablecontainers[key1] = (value1);
                            }
                        }
                    }  
                })
            }).catch(error => {
                console.log(error);
            })
        },
        methods:
        {
            reset(){Object.assign(this.$data, this.initialdata());},
            initialdata(){
                return{
                    creds:{zip:"",name:"",number:"",email:"",address:"",note:"",city:''},
                    arr_abfallart:[],
                    date: new Date().toISOString().substr(0, 10),
                    date_von:null,
                    containerselected:{0:null,1:null,2:null,3:null,4:null,5:null,6:null,7:null},
                    step:1,
                    appjson: importedjson,
                    design: { color: '#03A9F4', text:'text-white'},
                    containerindex:0,
                    addedContainers:0,
                    property: "privat",
                    sent:false,
                    popup:false,
                    drawer:false,
                }
            },
            testdata(){
                return{
                    creds:{zip:4614,name:"Alexander Blasl",number:"+4367763439947",email:"testemail@gmail.com",address:"Testadress 1"},
                    arr_abfallart:['erde','bio'],
                    date: new Date().toISOString().substr(0, 10),
                    date_von:null,
                    containerselected:{0:{"name":"Container S","img":"https://www.gutentsorgen.at/wp-content/uploads/2021/01/container_niedrig-1024x899.png","description":"Ohne Deckel","volume":"8","materials":["stein","erde","bio","holz","asbest","baurest","sperrmuell"]},1:{"name":"Container S","img":"https://www.gutentsorgen.at/wp-content/uploads/2021/01/container_niedrig-1024x899.png","description":"Ohne Deckel","volume":"8","materials":["stein","erde","bio","holz","asbest","baurest","sperrmuell"]},2:null,3:null,4:null,5:null,6:null,7:null},
                    step:5,
                    appjson: importedjson,
                    design: { color: 'light-blue', text:'text-white'},
                    containerindex:0,
                    addedContainers:2,
                    property: "privat",
                    sent:false,
                    popup:false,
                    drawer:false,
                }
            },
            next(){
                if(this.continueallowed){this.step++}
            },
            prev(){
                this.step--
            },
            isInArray(argArray,argFind ){
                //allcontainer.materials.includes('erde')
                if (typeof argFind !== 'string')
                {
                    var result = true;

                    argFind.forEach(element => {
                        if(argArray.includes(element) == false)
                        {
                            result = false
                        }
                    });

                    return result
                }else
                {
                    return argArray.includes(argFind)
                }
            },
            listAbfall(arg1)
            {
                var result = "";

                if(arg1 != null)
                {
                    console.log('List:')
                    arg1.forEach(element => {
                        console.log(element)
                        if(result === "")
                        {
                            result = ""+this.appjson.abfall[element].name
                        }else{
                            result += ", "+this.appjson.abfall[element].name
                        }
                    });
                }else
                {
                    for (const [key1,value1] of Object.entries(this.appjson.abfall)) {
                        //result += key1 + value1
                        key1
                        value1
                        if(value1.selected == true)
                        {
                            if(result === "")
                            {
                                result = ""+value1.name
                            }else{
                                result += ", "+value1.name
                            }
                        }
                    }
                }

                return result
            },
            listContainersUsed(){
                var r = ""
                for (let index = 0; index < this.addedContainers; index++) {
                    const element = this.containerselected[index];
                    if (r == "")
                    {
                        r += element.name
                    }else
                    {
                        r += ', ' + element.name
                    }
                }

                return r;
            },
            send(arg){
                var data = {}
                var selectedtmp = []

                //while (this.appjson.abfall[this.selectedWaste] != null) {selectedtmp.push(this.containerselected[selectedi].slug); selectedi++;}
                this.selectedWaste.forEach(element => {
                    selectedtmp.push(this.appjson.abfall[element].selectedcontainer.slug);
                });

                data = JSON.stringify({type:(arg == 'rueckruf') ? 'rueckruf' : 'anfrage',creds:this.creds,selectedcontainer:selectedtmp,deliverday:this.date,property:this.property,waste:this.selectedWaste});

                console.log('Send request');

                this.axios.get(this.appjson.settings.url+this.appjson.settings.folder+"/post.php?p=a&q="+btoa(unescape(encodeURIComponent(data))))

                console.log('Sent :)');
            },
            toggleContainer(localwaste,localsc){
                if(this.appjson.abfall[localwaste].selectedcontainer != null && this.appjson.abfall[localwaste].selectedcontainer == localsc)
                {
                    this.appjson.abfall[localwaste].selectedcontainer = null
                    this.appjson.containercount--
                }else
                {
                    if(this.appjson.abfall[localwaste].selectedcontainer == null)
                        this.appjson.containercount++
                    this.appjson.abfall[localwaste].selectedcontainer = localsc
                }   
            },
            shadowContainer(localwaste,localsc){
                return (!((this.appjson.abfall[localwaste].selectedcontainer == null)||(this.appjson.abfall[localwaste].selectedcontainer == localsc)))?'opacity:0.5':'border: 1px solid '+this.appjson.settings.design.color
            },
            elevateContainer(localwaste,localsc){
                return (((this.appjson.abfall[localwaste].selectedcontainer == null)||(this.appjson.abfall[localwaste].selectedcontainer == localsc)))?'10':'1'
            },
            toggleWaste(localwaste)
            {
                this.appjson.abfall[localwaste].selected = !this.appjson.abfall[localwaste].selected
            },
            scrollToTop() {
                window.scrollTo(0,0);
            }
        }
    }
</script>

<style>
    h3{
        font-weight: 700;
        display:block;
        border-bottom: 1.4px solid rgb(189, 189, 189);
    }   

    td{
        width:50%;
        padding:.3em;
    }

    tr{
        width:100%;
    }

    table{
        width:100%;
    }

    .v-stepper__content{
        padding: 7px !important;
    }

</style>