<template>
  <Container />
</template>

<script>
import Container from './components/Container.vue';


export default {
  name: 'App',
  components: {
    Container,
  },

  data: () => ({ drawer: null }),
};
</script>
