import Vue from 'vue'
import App from './App.vue'

import vuetify from '@/plugins/vuetify' // path to vuetify export

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueMeta from 'vue-meta'

Vue.use(VueAxios, axios)
Vue.use(VueMeta)


Vue.config.productionTip = false

import _ from 'lodash';    

Object.defineProperty(Vue.prototype, '$_', { value: _ });



new Vue({
  vuetify,
  VueAxios,
  render: h => h(App)
}).$mount('#app')
